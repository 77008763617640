import MyGroupModal from "components/groups/MyGroupModal.js";
import ViewGroupModal from "components/groups/ViewGroupModal.js";
import AnalysePostDialog from "components/posts/analysePost/analysePostDialog.js";
import DeletePost from "components/posts/deletePost/DeletePost.js";
import EditPostContainer from "components/posts/edit-post/EditPostContainer.js";
import ReportPost from "components/posts/report-post/index.js";
import { Card } from "components/shared/card";
import { DotFlashing } from "components/shared/dot-flashing";
import UserDetails from "components/user-details/index.js";
import { PAGINATION_SIZE } from "global";
import { useAuthState } from "lib/context/authContext.js";
import GlobalStateContext from "lib/context/global-state/GlobalStateContext.js";
import { useInfiniteScroll } from "lib/hooks/useInfiniteScroll";
import { isNullOrUndefined } from "lib/utils/isNullOrUndefined";
import { logError } from "lib/utils/logError";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getGroupPermissions, getGroupsWithPost, mappPostInGroup } from "../../../api/groups.js";
import { deletePost, getPosts, reportPost } from "../../../api/posts.js";
import SinglePost from "./SinglePost";
import ActionBar from "components/actionBar";
import { useAppSelector } from "lib/store";
import * as GlobalStore from "lib/store/global";

export interface IGroupFeedParams {
  token: string;
  groupId: any;
  groupData: any;
  cutTop?: boolean;
}

const GroupFeed = (props: IGroupFeedParams) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const query = useAppSelector(GlobalStore.selectQuery);
  const userDetails = useAuthState();
  const loadingRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<any[]>([]);
  // const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState(true);
  const [memberError, setMemberError] = useState(false);
  const [groupPerData, setGroupPerData] = useState(null);
  const [token, setToken] = useState<string | null>(null);
  const [isMember, setIsMember] = useState<boolean | null>(null);
  const [userDetailsID, setUserDetailsID] = useState<number | null>(null);
  const [userDetailsPost, setUserDetailsPost] = useState<number | null>(null);
  const [groupInfoID, setGroupInfoID] = useState<string | null>(null);
  const [analysePostID, setAnalysePostID] = useState<number | null>(null);
  const [sharePostID, setSharePostID] = useState<any | null>(null);
  const [groupsToShare, setGroupsToShare] = useState<any[]>([]);
  const [openEditPost, setOpenEditPost] = useState<boolean>(false);
  const [editPost, setEditPost] = useState<any | null>(null);
  const [deletePostID, setDeletePostID] = useState<number | null>(null);
  const [reportPostID, setReportPostID] = useState<number | null>(null);
  const [reportedPosts, setReportedPosts] = useState<any[]>([]);
  const [isPublicGroup, setIsPublicGroup] = useState<boolean>(false);

  const offset = useRef<number>(0);

  useInfiniteScroll(loadingRef, () => {
    if (!hasMore || loading) return;
    setLoading(true);
    loadPosts();
  });

  useEffect(() => {

    return () => {
      setGlobalState({ ...globalState, embedded: false, embeddedToken: null });
    };
  }, []);

  useEffect(() => {
    offset.current = 0;
    loadPosts(true);

    console.log(query, offset);
  },[query]);

  useEffect(() => {
    const embeddedToken = !isNullOrUndefined(globalState?.embeddedToken)
      ? globalState.embeddedToken
      : null;
    const userToken = !isNullOrUndefined(userDetails?.token) ? userDetails.token : null;
    const propsToken = !isNullOrUndefined(props?.token) ? props?.token : null;
    const newToken = embeddedToken ?? userToken ?? propsToken;
    if (newToken !== token) setToken(newToken);
  }, [userDetails?.token, globalState?.embeddedToken, props?.token]);

  useEffect(() => {
    if (!token && userDetails) {
      setMemberError(true);
      return;
    } else {
      setMemberError(false);
    }
    setLoading(true);
    getData().finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    if (isMember === undefined) return;
    loadPosts(true);
  }, [isMember]);

  async function getData() {
    const res = await getGroupPermissions(token, props.groupId);
    if (res.data.OK) {
      const permission = res.data.data;
      const isMember = permission.member === "1";
      const isPublicGroup = permission.public === "y";
      setGroupPerData(permission);
      if (!isMember && !isPublicGroup && !globalState.embedded) {
        setMemberError(true);
      } else {
        setMemberError(false);
      }
      setIsMember(isMember);
      setIsPublicGroup(isPublicGroup);
    } else {
      setMemberError(true);
      setIsMember(false);
    }
  }

  const loadPosts = async (firstLoad?: boolean) => {
    try {
      if (userDetails?.token && !token) return;
      setLoading(true);
      const publicPosts = !(userDetails.token && isMember);
      const res = await getPosts(token, offset.current, props.groupId, query, null, publicPosts);
      if (res.data.OK) {
        setPosts(firstLoad ? res.data.data : [...posts, ...res.data.data]);
        // setOffset(res.data.offset);
        offset.current = res.data.offset;
        setHasMore(res.data.data?.length >= PAGINATION_SIZE);

        if (publicPosts) {
          setIsPublicGroup(true);
        }
      }
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  const sharePostAction = (postID: number) => {
    if (sharePostID === null) {
      getGroupsWithPost(props.token, postID).then((res) => {
        if (res.data.OK) {
          const data = res.data.grp;
          setGroupsToShare(data);
          setSharePostID(postID);
        } else if (res.data.ER) {
          alert(res.data.ER);
        }
      });
      return;
    }
  };

  const onMySharedGrpClick = (data: any) => {
    mappPostInGroup(props.token, data.id, sharePostID).then((res) => {
      if (res.data.ER) {
        alert(res.data.ER);
      } else {
        setSharePostID(null);
        alert(t("publish_post_success"));
        if (data.id === 0) navigate("/public-feed");
        else {
          navigate("/group/" + data.id);
          window.location.reload();
        }
      }
    });
  };

  const editPostAction = (post: any) => {
    if (editPost === null) {
      setOpenEditPost(true);
      setEditPost(post);
      return;
    }
  };

  const deletePostAction = async (postID: number) => {
    if (deletePostID === null) {
      setDeletePostID(postID);
      return;
    }
    const res = await deletePost(props.token, postID);
    if (res.data.OK) {
      setPosts((posts) => posts.filter((x) => x.loci_postID !== postID));
    }
  };

  const reportPostAction = async (postID: number, message: string) => {
    if (reportPostID === null) {
      setReportPostID(postID);
      return;
    }
    const res = await reportPost(props.token, postID, message);
    if (res.data.OK) {
      setReportedPosts((posts) => [...posts, postID]);
    }
  };

  const userDetailsAction = (id: number, post?: any) => {
    setUserDetailsID(id);
    setUserDetailsPost(post ? post : null);
  };

  const groupInfoAction = (id: string) => {
    navigate(`/group/${id}`);
    // setGroupInfoID(id);
  };

  if (!loading && !posts?.length) {
    return (
      <Card cutTop>
        <p className='alertMeg'>{t("common.no-data")}</p>
      </Card>
    );
  }

  if (memberError && !isPublicGroup) {
    return (
      <Card cutTop>
        <p className='alertMeg'>{t("not_group_member")}</p>
      </Card>
    );
  }

  return (
    <>
      <ActionBar
        leftIcon={isMember ? "add" : undefined}
        onLeftClick={() => {
          setOpenEditPost(true);
          setEditPost(null);
        }}
        style={{ top: "calc(var(--header-height) + 78px)" }}
        containerStyle={{border:0}}
      />
      <section ref={loadingRef}>
        {posts?.map((post, index) => {
          return (
            <Card key={post.loci_postID} cutTop={index === 0 && props.cutTop}>
              <SinglePost
                token={token ?? props.token}
                post={post}
                isMember={!!isMember}
                analysePostAction={setAnalysePostID}
                sharePostAction={sharePostAction}
                editPostAction={editPostAction}
                deletePostAction={deletePostAction}
                reportPostAction={reportPostAction}
                userDetailsAction={userDetailsAction}
                groupInfoAction={groupInfoAction}
              />
            </Card>
          );
        })}

        <DotFlashing loading={loading} />

        {/* Post Action Modals */}
        {sharePostID && (
          <MyGroupModal
            {...props}
            data={groupsToShare || []}
            type='sharedGroups'
            editing={true}
            onCardClick={onMySharedGrpClick}
            closeModal={() => setSharePostID(null)}
          />
        )}

        {openEditPost && (
          <EditPostContainer
            token={props.token}
            editing={true}
            editedPost={editPost}
            isEditable={!!editPost}
            groupId={props.groupId}
            groupData={groupPerData}
            stopEdit={() => {
              setEditPost(null); 
              setOpenEditPost(false);
            }}
            location={location}
          />
        )}

        {deletePostID !== null && (
          <DeletePost
            deleting={true}
            handleClose={() => setDeletePostID(null)}
            isDeleted={false}
            deletePost={deletePostAction}
            token={props.token}
            postID={deletePostID}
          />
        )}

        {reportPostID !== null && (
          <ReportPost
            reporting={true}
            handleClose={() => setReportPostID(null)}
            reportPost={(message: string) => reportPostAction(reportPostID, message)}
            isReported={reportedPosts.includes(reportPostID)}
          />
        )}

        {analysePostID && (
          <AnalysePostDialog
            analysing={true}
            handleClose={() => setAnalysePostID(null)}
            token={props.token}
            postID={analysePostID}
          />
        )}

        {userDetailsID !== null && (
          <UserDetails
            id={userDetailsID}
            postId={userDetailsPost}
            handleClose={() => setUserDetailsID(null)}
            token={props.token}
            showFollowOption={userDetailsPost ? true : false}
          />
        )}

        {groupInfoID !== null && (
          <ViewGroupModal
            groupId={groupInfoID}
            handleClose={() => setGroupInfoID(null)}
            token={props.token}
          />
        )}
      </section>
    </>
  );
};

export default GroupFeed;
