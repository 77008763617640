import InfoCard from "./InfoCard";
import GroupFeed from "./feed/index";
import { FeedContainer, LeftGrid, WidgetCard } from "./style";

export interface IPostsTabParams {
  token: string;
  groupId: any;
  groupData: any;
}

export const PostsTab = (props: IPostsTabParams) => {
  return (
    <>
      <LeftGrid>
        <WidgetCard $sticky={true}>
          <InfoCard data={props.groupData} />
        </WidgetCard>
      </LeftGrid>
      <FeedContainer $maxWidth="550px" $width="100%">
        <GroupFeed {...props} cutTop={false} />
      </FeedContainer>
    </>
  );
};
