import React, { useState } from "react";
import BackButton from "../../commons/BackButton";
import { LocciAvatar } from "../../commons/LocciAvatar";
import {
  withRouter,
} from "../../customNavigate/withRouter";
import "./groupChatInfo.scss";
import IconButton from "../../elements/buttons/IconButton";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import MediaTabGrp from "./groupInfoTabs/mediaTab";
import FileList from "../../upload";
import DocTabGrp from "./groupInfoTabs/docTab";
import LinkTabGrp from "./groupInfoTabs/linkTab";
import DeleteModal from "../../commons/DeleteModal";
import GlobalConfirmDialogue from "../../commons/GlobalConfirmDialogue";
import { withScrollHookHOC } from "../chat/CustomScoller";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { mapStateToProps } from "lib/store/mapStateToProps";
import * as GlobalStore from 'lib/store/global';
import { SOCKET_URL } from "../../../global";
import { updateChatGroupAvatar } from "../../../api/chat";
import ToastModal from "../../commons/ToastModal";
import Lightbox from "../../elements/lightbox";

import { useSocketContext } from "lib/context/socketContext";
import ActionBar from "components/actionBar";

class GroupChatInfo extends React.Component {
  constructor(props) {
    super(props);
    this.ws = React.createRef()
    this.state = {
      // ws: null,
      roomId: props?.match?.params?.id,
      userId: props?.profile?.userId,
      avatar: [],
      avatarLoader: false,
      groupName: "",
      showToast: false,
      toastMessage: "",
      mediaInfo: {},
      photos: [],
      docs: [],
      links: [],
      isLightboxOpen: false,
      lightboxMedia: [],
      lightboxIndex: 0,
      roomType: "",
      updateGroup: false,
      canSearch: false,
      toggleSearch: false,
    };
  }

  state = {
    editTitle: false,
    headerData: {},
    deleteGroup: false,
    key: "media",
  };

  componentDidMount() {
    this.connect();

    if (this.props?.location.pathname.includes("group-chat-info")) {
      this.setState({
        roomType: "group",
      });
    } else {
      this.setState({
        roomType: "private",
      });
    }

    this.props.toggleGlobalSet(false)
  }

  componentDidUpdate() {
    if (this.ws.current?.readyState != this.props.socketContext.webScoketRef.current?.readyState) {
      if (this.props.socketContext.webScoketRef.current?.readyState === WebSocket.OPEN) {
        this.ws.current = this.props.socketContext.webScoketRef.current;
        this.connect();
      }
    }
  }

  timeout = 250; // Initial timeout duration as a class variable

  connect = () => {
    let that = this; // cache the this
    this.setState({ avatarLoader: true });

    this.ws.current = this.props.socketContext.webScoketRef.current;

    if (this.props.socketContext.webScoketRef.current === null) {
      this.props.socketContext.initializeWebSocket();
    }

    if (this.ws?.current?.readyState === WebSocket.OPEN) {
      this.getPersonRoomId();
      
      this.ws.current.onmessage = (event) => {
        let eventData = JSON.parse(event.data);
  
        if (eventData.event === "room_info") {
          this.setState({
            avatarLoader: false,
            headerData: eventData,
            avatar: eventData.CAR_avatar
              ? [
                {
                  preview: eventData.CAR_avatar ? eventData.CAR_avatar : "",
                },
              ]
              : [],
            groupName: eventData.CAR_room,
          });
          this.setState({ mediaInfo: eventData.media });
          this.filterMediaInfo(eventData.media);
        } else if (eventData.event === "get_chat_room") {
          console.log(eventData);
          this.getRoomInfo(eventData.roomID);
        } else if (eventData.event === "chat_user_list") {
          this.setState({
            messages: eventData.list,
          });
        } else if (eventData.event === "delete_group") {
          this.setState({ showToast: true, toastMessage: eventData.message });
          setTimeout(this.redirectToChat, 2000);
        } else if (
          eventData.event === "update_room_info" &&
          eventData.action === "name_update"
        ) {
          let image = this.state.avatar;
  
          if (image.length === 1 && image[0].delete) {
            this.deleteRoomAvatar();
          } else if (image.length === 1 && image[0].type) {
            let images = this.state.avatar.filter((me) => me.type);
            this.manageEditInfo(images);
          } else if (image.length > 1) {
            let images = this.state.avatar.filter((me) => me.type);
            this.manageEditInfo(images);
          } else {
            this.setState({ editTitle: false });
            this.getPersonRoomId();
          }
  
          this.setState({ updateGroup: false });
        } else if (eventData.event === "delete_room_avatar") {
          this.getPersonRoomId();
          this.setState({ editTitle: false, avatarLoader: false });
        }
      }
    }

  };

  redirectToChat = () => {
    this.props.history.push("/inbox");
  };

  filterMediaInfo = (data) => {
    let p = [];
    let d = [];
    let l = [];

    data?.map((dt) => {
      if (dt?.LC_message_type === "attachment" && dt?.CTA_type !== "d" && dt?.CTA_type !== "a" && dt?.CTA_type !== "c") {
        p.push(dt);
      } else if ((dt?.LC_message_type === "attachment" && dt?.CTA_type === "d") || (dt?.CTA_type !== "d" && dt?.CTA_type === "a") || dt?.CTA_type === "c") {
        d.push(dt);
      } else if (dt?.LC_message_type === "text") {
        l.push(dt);
      }
    });

    this.setState({
      photos: p,
      docs: d,
      links: l,
    });
  };

  getRoomInfo = (id) => {
    let roomId = this.props?.match?.params?.id;
    let userId = this.props?.profile?.userId;

    let params = {};

    if (this.state.roomType === "group") {
      params = {
        event: "room_info",
        LC_room_id: roomId,
      };
    } else {
      params = {
        event: "room_info",
        LC_room_id: id,
        user_id: roomId,
      };
    }

    let data = JSON.stringify(params);
    try {
      this.ws.current.send(data);
    } catch (e) {
      console.error(e);
    }
  };

  getPersonRoomId = () => {
    let params = {
      event: "get_chat_room",
      LC_to_user: this.state.roomId,
    };
    let data = JSON.stringify(params);

    try {
      this.ws.current.send(data);
    } catch (e) {
      console.error(e);
    }
  };

  getChatMsgList = () => {
    let roomId = this.state.roomId;
    let userId = this.state.userId;
    let params = {
      event: "chat_user_list",
      from_user_id: userId,
      LC_room_id: roomId,
      page_no: 1,
      record_per_page: 200,
    };
    let data = JSON.stringify(params);
    try {
      this.ws.current.send(data);
    } catch (e) {
      console.error(e);
    }
  };

  deleteRoomAvatar = () => {
    let roomId = this.state.roomId;
    let params = {
      event: "delete_room_avatar",
      LC_room_id: roomId,
    };
    let data = JSON.stringify(params);
    try {
      this.ws.current.send(data);
    } catch (e) {
      console.error(e);
    }
  };

  changeGroupChatName = () => {
    let roomId = this.state.roomId;
    let params = {
      event: "update_room_info",
      action: "name_update",
      LC_room_id: roomId,
      data: {
        MES_group_name: this.state.groupName,
      },
    };
    let data = JSON.stringify(params);
    try {
      this.ws.current.send(data);
    } catch (e) {
      console.error(e);
    }
  };

  sendEventHandler = (params) => { };

  handleTabSelect = (param) => {
    this.setState({ key: param });

    if (param === "media") {
      this.props.toggleGlobalSet(false)
      this.setState({canSearch: false})
    }
    else {
      this.setState({canSearch: true})
      this.props.toggleGlobalSet(true)
    }
  };

  setAvatar = (avtr) => {
    this.setState({ avatar: avtr });
  };

  manageEditInfo = (image) => {
    this.setState({ avatarLoader: true });

    updateChatGroupAvatar(this.props.token, this.state.roomId, image)
      .then((res) => {
        if (!res.data.OK) {
          this.setState({ editTitle: false, avatarLoader: false });
        }
      })
      .finally(() => {
        this.setState({ editTitle: false, avatarLoader: false });
        this.getPersonRoomId();
      });
  };

  handleDeleteGroup = () => {
    let params = {
      event: "delete_group",
      LC_room_id: this.state.roomId,
    };
    let data = JSON.stringify(params);
    try {
      this.ws.current.send(data);
    } catch (e) {
      console.error(e);
    }
    this.setState({ deleteGroup: false });
  };

  handleGroupNameChange = (value) => {
    this.setState({ groupName: value });
  };

  previewImage = (media, lightboxIndex) => {
    console.log(media, lightboxIndex);

    console.log("asdfsdffd", media)

    if (media && media.length > 0) {
      this.setState({
        lightboxMedia: media,
        lightboxIndex: lightboxIndex,
        isLightboxOpen: true,
      });
    }
  };


  handleSearchToggle = () => {
    this.setState(prevState => ({
      toggleSearch: !prevState.toggleSearch
    }), () => {
      this.props.search("");
    });
  };

  render() {
    const { t } = this.props;

    // console.log(this.state.ws,"render sdsd");
    return (
      <div className="container">
        <div className="group-title">
          <div className="avatar-title">
            <div style={{ marginRight: "10px" }}>
              <BackButton />
            </div>
            {this.state.editTitle ? (
              <div className="avatar-box">
                {
                  <FileList
                    id={"mediaUpload"}
                    fileTypes={".png, .jpg, .jpeg"}
                    setFiles={this.setAvatar}
                    files={this.state.avatar}
                    icon="add_a_photo"
                    className="groupMediaUpload"
                    single={true}
                    previews={true}
                  />
                }
              </div>
            ) : (
              <LocciAvatar
                title={this.state?.headerData?.CAR_room}
                picture={this.state.headerData?.CAR_avatar}
                size={"60px"}
                //     onAvatarClick={() => this.onAvatarClick()}
                className="cursor"
              />
            )}
            {this.state.editTitle ? (
              <div className="grpInfoHeadInput">
                <input
                  value={this.state.groupName}
                  onChange={(e) => this.handleGroupNameChange(e.target.value)}
                  placeholder="please enter name"
                />
              </div>
            ) : (
              <div className="grpInfoHeadTites">
                <h2 className="receiver">{this.state?.headerData?.CAR_room}</h2>
                {this.state.roomType === "group" && this.state?.headerData && (
                  <p>
                    {this.state?.headerData?.participants?.length}{" "}
                    {this.state?.headerData?.participants?.length > 1
                      ? t("participants")
                      : t("participant")}
                  </p>
                )}
              </div>
            )}

            <div className="actions">
              {this.state.editTitle ? (
                <>
                  <IconButton
                    icon="check"
                    clear={true}
                    disabled={this.state.groupName === ""}
                    handleClick={() => this.setState({ updateGroup: true })}

                  />
                  <IconButton
                    icon="delete"
                    clear={true}
                    handleClick={() => this.setState({ deleteGroup: true })}
                  />
                </>
              ) : (
                <>
                  {this.state.canSearch && (
                    <IconButton
                      icon={this.state.toggleSearch ? "close" : "search"}
                      className="black"
                      clear="true"
                      handleClick={this.handleSearchToggle}
                    />
                  )}
                  {this.state.headerData?.admin && (
                    <IconButton
                      icon="edit"
                      clear={true}
                      handleClick={() => this.setState({ editTitle: true })}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {this.state.canSearch && this.state.toggleSearch && (
            <ActionBar
              sticky={false} 
              style={{maxWidth: 600, margin:'-20px auto'}} 
              containerStyle={{border:0}}
            />
          )}
        </div>
        {
          <div className="grpInfoPage">
            <div className="grpInfoTab">
              <Tabs
                justify
                className="tab-innerr"
                id="controlled-tab-example"
                activeKey={this.state.key}
                onSelect={(k) => this.handleTabSelect(k)}
                unmountOnExit={true}
              >
                <Tab
                  eventKey="media"
                  title={t("media")}
                  tabClassName={`text-uppercase text-center tabss w-100 ${this.state.key === "media" && "active"
                    }`}
                >
                  <MediaTabGrp
                    previewImage={(img, idx) => this.previewImage(img, idx)}
                    media={this.state.photos}
                  />
                </Tab>
                <Tab
                  eventKey="docs"
                  title={t("docs")}
                  tabClassName={`text-uppercase text-center tabss  w-100 ${this.state.key === "docs" && "active"
                    }`}
                >
                  <DocTabGrp
                    roomId={this.state.roomId}
                    token={this.props.token}
                    docs={this.state.docs}
                    search={this.props.query}
                  />
                </Tab>
                <Tab
                  eventKey="links"
                  title={t("links")}
                  tabClassName={`text-uppercase text-center tabss  w-100 ${this.state.key === "links" && "active"
                    }`}
                >
                  <LinkTabGrp
                    links={this?.state?.links}
                    search={this?.props?.query}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        }
        {this.state.avatarLoader && (
          <div className="sending-overlay">
            <div class="loader">
              <Spinner
                animation="border"
                role="status"
                color="#00395c"
              ></Spinner>
            </div>
          </div>
        )}
        {this.state.deleteGroup && (
          <GlobalConfirmDialogue
            show={this.state.deleteGroup}
            title={"delete_grp_heading"}
            content={"delete_grp_content"}
            name={this.state.headerData.CAR_room}
            closeModal={() => this.setState({ deleteGroup: false })}
            onSubmit={() => this.handleDeleteGroup()}
          />
        )}
        {this.state.updateGroup && (
          <GlobalConfirmDialogue
            show={this.state.updateGroup}
            title={"update_group_heading"}
            content={"update_group_info_ask"}
            // name={""}
            closeModal={() => this.setState({ updateGroup: false })}
            onSubmit={() => this.changeGroupChatName()}
          />
        )}
        {this.state.showToast && <ToastModal msg={this.state.toastMessage} />}
        {this.state.isLightboxOpen && (
          <Lightbox
            nameDate={true}
            onClose={() => this.setState({ isLightboxOpen: false })}
            media={this.state.lightboxMedia}
            index={this.state.lightboxIndex}
            setIndex={(i) => this.setState({ lightboxIndex: i })}
          />
        )}
      </div>
    );
  }
}

const withSocketContext = (Component) => {
  const Wrapper = (props) => {
    const context = useSocketContext();

    return <Component socketContext={context} {...props} />;
  };

  return Wrapper;
};

const mapDispatchToProps = {
  toggleGlobalSet: GlobalStore.toggleGlobalSet,
  search: GlobalStore.search,
}

export default 
  withTranslation()(connect(mapStateToProps,mapDispatchToProps)(withRouter(withScrollHookHOC(withSocketContext(GroupChatInfo)))));
