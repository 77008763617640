import React, { Component } from "react";
import {
  deletePost,
  getComments,
  getPosts,
  addComment,
  reportPost,
  deleteComment,
  getPostData,
  fetchNewsData,
  applyFilterOnPosts,
  getFilterPosts,
} from "../../api/posts";
import {
  getGroupAbout,
  getGroupsWithPost,
  mappPostInGroup,
} from "../../api/groups";
import Lightbox from "../elements/lightbox";
import "./publicfeed.scss";
import { withRouter } from "../customNavigate/withRouter";
import EditPost from "../posts/edit-post";
import AnalysePostDialog from "../posts/analysePost/analysePostDialog";

import IconButton from "../elements/buttons/IconButton";
import ReportPost from "../posts/report-post";
import UserDetails from "../user-details";
import GroupPortal from "../groups/GroupPortal";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import classNames from "classnames";
import { LocciAvatar } from "../commons/LocciAvatar";
import { withTranslation } from "react-i18next";
import ViewGroupModal from "../groups/ViewGroupModal";
import DeletePost from "../posts/deletePost/DeletePost";
import BackButton from "../commons/BackButton";
import News from "../news/news";
import EditPostContainer from "./edit-post/EditPostContainer";
import MyGroupModal from "../groups/MyGroupModal";
// import locciIcon from "../../assets/newsIcon.png";
import SinglePost from "components/single-group/feed/SinglePost";
import { Card } from "components/shared/card/styled";
import ActionBar from "components/actionBar";
import FilterDropdown from "components/actionBar/FilterDropdown";

// import { Alert } from 'react-alert'

class PublicFeed extends Component {
  postFilterRef = [];
  state = {
    images: [],
    isLightboxOpen: false,
    lightboxMedia: [],
    lightboxIndex: 0,
    editing: false,
    editedPost: {},
    isEditable: false,
    showGroupPortal: false,
    canCreatePost: true,
    isAdmin: false,
    isSuperAdmin: false,
    query: "",
    groupData: null,
    isGroupInfo: false,
    groupId: null,
    showSideButtons: true,
    deletePostId: 0,
    analysing: false,
    analysePostId: 0,
    userId: null,
    isUserPostsRender: false,
    postId: null,
    isPostDetailsRender: false,
    noDatatitle: "",
    isPostShareAble: null,
    isNewsVisible: false,
    newsData: null,
    grpIdOnPersonal: null,
    shouldCallPersonlizedAPI: false,
    showSharedGroups: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      loading: true,
      offset: 0,
      prevY: 0,
      canCreatePost: true,
      showSideButtons: true,
      location: this.props.location,
      isNewsVisible: false,
      isEditable: false,
      shouldCallPersonlizedAPI: false,
    };
  }

  componentDidMount() {
    if (this.props.userId === undefined && this.props.groupId === undefined)
      this.checkReferredPost();

    this.geNewsData();

    if (this.props.groupId) {
      this.initGroupData();
    }

    this.observer = new IntersectionObserver(this.handleObserver.bind(this), {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });
    this.observer.observe(this.loadingRef);
  }

  async geNewsData() {
    try {
      const response = await fetchNewsData(this.props.token);

      if (response.data.OK) {
        const data = response.data.OK;
        this.setState({
          loading: false,
          isNewsVisible: true,
          newsData: data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getPostById() {
    try {
      const response = await getPostData(this.props.token, this.props.postId);
      if (response.data.OK) {
        const data = response.data.OK;
        const post = [];
        post.push(data);
        this.setState({
          posts: post,
          loading: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  initGroupData() {
    console.log("called");
    getGroupAbout(this.props.token, this.props.groupId).then((res) => {
      console.log("publicfeed data", res.data.OK);

      if (res.data.OK) {
        this.setState({
          canCreatePost:
            res.data.OK.LGP_post === "y" ||
            res.data.OK.superAdmin === "1" ||
            res.data.OK.admin === "1",
        });
        this.setState({
          isAdmin: res.data.OK.admin === "1",
          isSuperAdmin: res.data.OK.superAdmin === "1",
        });
        this.setState({
          groupData: {
            groupName: res.data.OK.LGP_name,
            avatar: res.data.OK.UCT_avatar,
            isPostShareAble: res.data.OK.LGP_shareable,
          },
        });
      }
    });
  }

  componentWillUnmount() {
    this.observer.unobserve(this.loadingRef);
  }

  componentDidUpdate() {
    // if (this.props?.postFilter && this.state.location.pathname === "/") {
    //   let result = this.compareArray(
    //     this.props?.postFilter,
    //     this.postFilterRef
    //   );

    //   if (!result) {
    //     this.postFilterRef =
    //       this.props?.postFilter?.length > 0
    //         ? [...this.props?.postFilter]
    //         : [...this.postFilterRef];
    //     this.applyFilterOnPosts();
    //   }
    // }

    if (
      this.props.shouldCallPersonlizedAPI !==
      this.state.shouldCallPersonlizedAPI
    ) {
      this.setState({
        shouldCallPersonlizedAPI: this.props.shouldCallPersonlizedAPI,
        offset: 0,
      });

      this.getFilteredPost(true);
    }

    if (this.state.query !== this.props.query) {
      this.setState({ query: this.props.query, offset: 0 });
      // console.log("1");
      this.getFilteredPost(true);
    }
  }

  closeNews() {
    this.setState({
      isNewsVisible: false,
    });
  }

  checkReferredPost() {
    // console.log('d');
    this.referredPost = new URLSearchParams(this.props.location.search).get(
      "p"
    );
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      this.getFilteredPost();
    }
    this.setState({ prevY: y });
  }

  openLightBox = (media, lightboxIndex) => {
    if (media && media.length > 0) {
      this.setState({
        lightboxMedia: media,
        lightboxIndex: lightboxIndex,
        isLightboxOpen: true,
      });
    }
  };

  getFilteredPost(reset = false) {
    getFilterPosts(
      this.props.token,
      reset ? 0 : this.state.offset,
      this.props.groupId,
      this.props.query,
      this.referredPost
    ).then((res) => {
      //reset after first query
      if (res.data.data) {
        const data =
          res.data.data.length < 1 && this.props.query === ""
            ? this.setState({ noDatatitle: "no_post_in_region" })
            : this.setState({ noDatatitle: "no_post_found" });
        if (reset) {
          this.setState({
            posts: res.data.data,
            loading: false,
            offset: res.data.data?.length === 1 ? 1 : res.data.offset,
          });
        } else {
          this.setState({
            posts: [...this.state.posts, ...res.data.data],
            loading: false,
            offset: res.data.data?.length === 1 ? 1 : res.data.offset,
          });
        }
      } else {
        if (this.state.location.pathname.includes("group"))
          this.setState({ noDatatitle: "no_post_in_group" });
        else this.setState({ noDatatitle: "no_post_in_region" });

        if (reset) this.setState({ posts: [], loading: false });
        else this.setState({ loading: false });
      }
    });
  }

  deletePost = (postID) => {
    deletePost(this.props.token, postID).then((res) => {
      if (res.data.OK) {
        this.setState({
          posts: this.state.posts.filter((x) => x.loci_postID !== postID),
        });
      }
    });
  };

  reportPostAction = (message) => {
    reportPost(this.props.token, this.state.reportedPost, message).then(
      (res) => {
        if (res.data.OK) {
          this.setState({ isReported: true });
        }
      }
    );
  };
  editPost = (post) => {
    // if(post?.FK_user_categoryID===0)  {

    // }

    this.setState({
      editing: true,
      isEditable: true,
      editedPost: post,
      grpIdOnPersonal:
        post?.FK_user_categoryID !== "0" || null
          ? post?.FK_user_categoryID
          : undefined,
    });
  };

  analyzePost = (post) => {
    this.setState({
      analysing: true,
    });
  };

  getComments = (postID) => {
    return getComments(this.props.token, postID);
  };

  addComment = (postID, msg) => {
    return addComment(this.props.token, postID, msg);
  };
  deleteComment = (commentId) => {
    return deleteComment(this.props.token, commentId);
  };
  // onHashTagClick = (value) =>{
  //   dispatch(search(value))
  // }
  openUserDetails = (userID, postID) => {
    this.setState({
      isUserDetails: true,
      userDetailsID: userID,
      userDetailsPost: postID,
    });
  };

  openGroupInfoPage = (groupId) => {
    this.setState({ isGroupInfo: true, groupId: groupId });
  };

  openUserPostsPage = (userId) => {
    this.setState({ isUserPostsRender: true, userId: userId });
  };

  openPostDetailsPage = (postId) => {
    this.setState({ isPostDetailsRender: true, postId: postId });
  };

  compareArray = (a, b) => {
    return a.length === b.length && a.every((v, i) => v === b[i]);
  };


  sharePostAction = (data) => {
    this.fetchGroups(data);
  };

  fetchGroups = (id) => {
    console.log("aaaa");

    getGroupsWithPost(this.props.token, id).then((res) => {
      if (res.data.OK) {
        const data = res.data.grp;
        // let modifiedGroups = [
        //   {
        //   "id": "public",
        //   "name": "Regional Feed",
        //   "userid": "public",
        //   "admin": "1",
        //   "superAdmin": "public",
        //   "userpic": locciIcon,
        //   "allow_post": "1",
        //   "promo": "public",
        //   "UCM_IsPersonalized": "y",
        //   "community": "public",
        //   "created": "2023-02-17 08:30:17"

        // },
        // ...data
        // ]
        this.setState({
          postId: id,
          showSharedGroups: true,
          mySharedGroups: data,
        });
      }
    });
  };

  onMySharedGrpClick = (data) => {
    const { t } = this.props;
    console.log(data);
    mappPostInGroup(this.props.token, data.id, this.state.postId).then(
      (res) => {
        if (res.data.ER) {
          alert(res.data.ER);
        } else {
          this.setState({
            showSharedGroups: false,
          });
          alert(t("publish_post_success"));
          if (data.id === 0) this.props.history.push("/public-feed");
          else this.props.history.push("/group/" + data.id);
        }
      }
    );
  };


  render() {
    const posts = this.state.posts;
    const { t } = this.props;
    
    return (
      <>
        <div className="feed main-content">
          <div className="container">
            <ActionBar
              leftIcon="add"
              onLeftClick={() => this.setState({ editing: true })}
              rightComponent={<FilterDropdown token={this.props.token} />}
            ></ActionBar>
            {this.state.location.pathname === "/" ? (
              ""
            ) : (
              <div className="pt-3 pb-1">
                <BackButton />
              </div>
            )}

            {/* {this.state.groupData && ( */}
            { this.props.location.pathname !== "/" &&
              <>
                <div className="group-title">
                  <div className="avatar-title">
                    {this.state.groupData && (
                      <div className="pr-3">
                        <BackButton />
                      </div>
                    )}
                    <LocciAvatar
                      className={"borderAvatar"}
                      title={
                        this.state.groupData?.groupName ||
                        t("personal-feed-title")
                      }
                      picture={this.state.groupData?.avatar}
                      size={"60px"}
                    />
                    <h2>
                      {this.state.groupData?.groupName ||
                        t("personal-feed-title")}
                    </h2>
                  </div>
                </div>

                <div className="place-holder"></div>
              </>
            }
            {/* )} */}

            {/* {this.state.isNewsVisible &&
              this.state.location.pathname === `/` && (
                <div className="news-main-container">
                  {" "}
                  <News
                    token={this.props.token}
                    data={this.state.newsData}
                    close={() => this.closeNews()}
                  />
                </div>
              )} */}

            {/* {this.state.isNewsVisible &&
              this.state.location.pathname ===
                `/group/${this.props.groupId}` && (
                <div className="news-main-container nomarginb">
                  {" "}
                  <News
                    token={this.props.token}
                    data={this.state.newsData}
                    close={() => this.closeNews()}
                    displayOn="group"
                  />
                </div>
              )} */}
            {console.log("admin", posts, this.state.superAdmin)}
            {posts.map((item, i) => (
              <Card key={item.loci_postID}>
                <SinglePost
                  token={this.props.token}
                  isMember={true}
                  showGroupLink={true}
                  post={item}
                  analysePostAction={() =>
                    this.setState({
                      analysing: true,
                      analysePostId: item.loci_postID,
                      token: this.props.token,
                    })
                  }
                  sharePostAction={() => this.sharePostAction(item.loci_postID)}
                  editPostAction={(post) => this.editPost(post)}
                  deletePostAction={(postID) =>
                    this.setState({
                      deleting: true,
                      deletePostId: postID,
                      isDeleted: false,
                      deletedPost: item.loci_postID,
                      token: this.props.token,
                    })
                  }
                  reportPostAction={() =>
                    this.setState({
                      reporting: true,
                      isReported: false,
                      reportedPost: item.loci_postID,
                    })
                  }
                  userDetailsAction={(id, post) =>
                    this.openUserDetails(post.FK_userID, post.loci_postID)
                  }
                  groupInfoAction={(id) => {
                    // this.setState({ isGroupInfo: true, groupId: id });
                    this.props.history.push(`/group/${id}`);
                  }}
                />
              </Card>
                // data={item}
                // className={classNames({
                //   featured: this.referredPost === item.loci_postID,
                // })}
                // token={this.props.token}
                // openLightBox={this.openLightBox}
                // editPost={this.editPost}
                // analyzePost={() =>
                //   this.setState({
                //     analysing: true,
                //     analysePostId: item.loci_postID,
                //     token: this.props.token,
                //   })
                // }
                // deletePost={(postID) =>
                //   this.setState({
                //     deleting: true,
                //     deletePostId: postID,
                //     isDeleted: false,
                //     deletedPost: item.loci_postID,
                //     token: this.props.token,
                //   })
                // }
                // reportPost={() =>
                //   this.setState({
                //     reporting: true,
                //     isReported: false,
                //     reportedPost: item.loci_postID,
                //   })
                // }
                // openUserDetails={() =>
                //   this.openUserDetails(item.FK_userID, item.loci_postID)
                // }
                // getComments={this.getComments}
                // addComment={this.addComment}
                // deleteComment={this.deleteComment}
                // favoritePost={this.favoritePost}
                // likePost={this.likePost}
                // groupId={this.props.groupId}
                // isAdmin={this.state.isAdmin}
                // isSuperAdmin={
                //   this.state.isSuperAdmin || item?.my === "y" ? true : false
                // }
                // isPostShareAble={this.state.isPostShareAble}
                // openGroupInfoPage={() =>
                //   this.openGroupInfoPage(item.OrgGroupId)
                // }
                // openUserPostsPage={() =>
                //   this.openUserPostsPage(this.props.userId)
                // }
                // showUserDetails={(data) =>
                //   this.openUserDetails(data.FK_userID, data.loci_postID)
                // }
                // sharePostAction={() => this.sharePostAction(item.loci_postID)}
            ))}

            {this.state.location.pathname === `/group/${this.props.groupId}` &&
              posts.length < 1 &&
              this.state.noDatatitle !== undefined && (
                <div className="text-center">
                  {t(`${this.state.noDatatitle}`)}
                </div>
              )}

            {this.state.location.pathname === `/` &&
              posts.length < 1 &&
              this.state.noDatatitle !== undefined && (
                <div className="text-center">
                  {t(`${this.state.noDatatitle}`)}
                </div>
              )}
          </div>
          <div
            className={
              "dot-flashing " + (this.state.loading ? " " : "invisible")
            }
            ref={(loadingRef) => (this.loadingRef = loadingRef)}
          ></div>

          {this.state.isLightboxOpen && (
            <Lightbox
              onClose={() => this.setState({ isLightboxOpen: false })}
              media={this.state.lightboxMedia}
              index={this.state.lightboxIndex}
              setIndex={(i) => this.setState({ lightboxIndex: i })}
            />
          )}

          {this.state.editing && (
            <EditPostContainer
              token={this.props.token}
              isEditable={this.state.isEditable}
              editing={this.state.editing}
              editedPost={this.state.editedPost}
              groupId={this.props.groupId || this.state.grpIdOnPersonal}
              stopEdit={() =>
                this.setState({ isEditable: false, editing: false })
              }
              location={this.state.location.pathname}
            />
          )}

          {this.state.analysing && (
            <AnalysePostDialog
              analysing={this.state.analysing}
              handleClose={() => this.setState({ analysing: false })}
              token={this.props.token}
              postID={this.state.analysePostId}

              // isEditable={this.state.isEditable}
              // editing={this.state.editing}
              // editedPost={this.state.editedPost}
              // groupId={this.props.groupId}
            />
          )}

          {this.state.deleting && (
            <DeletePost
              deleting={this.state.deleting}
              handleClose={() => this.setState({ deleting: false })}
              deletePost={this.deletePost}
              isDeleted={this.state.isDeleted}
              token={this.props.token}
              postID={this.state.deletePostId}
            />
          )}

          {this.state.reporting && (
            <ReportPost
              reporting={this.state.reporting}
              handleClose={() => this.setState({ reporting: false })}
              reportPost={this.reportPostAction}
              isReported={this.state.isReported}
            />
          )}

          {this.state.isUserDetails && (
            <UserDetails
              id={this.state.userDetailsID}
              postId={this.state.userDetailsPost}
              handleClose={() => this.setState({ isUserDetails: false })}
              token={this.props.token}
              showFollowOption={this.state.userDetailsPost ? true : false}
            />
          )}
          {this.state.isGroupInfo && (
            <ViewGroupModal
              groupId={this.state.groupId}
              handleClose={() => this.setState({ isGroupInfo: false })}
              token={this.props.token}
            />
          )}

          <div className="newPostBtnHolder">
            {this.state.showSideButtons && (
              <IconButton
                icon="add"
                text={t("add_edit_post_create")}
                type="button"
                disabled={!this.state.canCreatePost}
                handleClick={() => this.setState({ editing: true })}
                className="create-post-btn"
              />
            )}
            {this.props.groupId && this.state.showSideButtons && (
              <IconButton
                icon="groups"
                text={
                  t("group_portal_nav_title_primary") +
                  " " +
                  t("group_portal_nav_title_secondary")
                }
                type="button"
                handleClick={() => this.setState({ showGroupPortal: true })}
              />
            )}
          </div>
        </div>
        {this.state.showGroupPortal && (
          <GroupPortal
            showGroupPortal={this.state.showGroupPortal}
            handleClose={() => this.setState({ showGroupPortal: false })}
            groupId={this.props.groupId}
          />
        )}

        {this.state.showSharedGroups && (
          <MyGroupModal
            data={this.state.mySharedGroups || []}
            type="sharedGroups"
            editing={this.state.showSharedGroups}
            {...this.props}
            onCardClick={(data) => this.onMySharedGrpClick(data)}
            closeModal={() =>
              this.setState({
                showSharedGroups: false,
              })
            }
          />
        )}
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(withRouter(PublicFeed)));
