import styled, { css } from "styled-components";
import { Dropdown } from "react-bootstrap";

export const Wrapper = styled.div<{ $sticky?: boolean }>`
    margin-bottom: 5px;

    ${(props) =>
        props.$sticky &&
        css`
            position: sticky;
            top: var(--header-height);
            z-index: 1001;
        `}
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 20px 20px;
    margin-bottom: 5px;
    border-radius: 0 0 8px 8px;
    border: 1px solid #eeeeee;
    gap: 20px;
`;

export const LeftComponentWrapper = styled.div`
    display:flex;
    align-items: center;
`;

export const CenterComponent = styled.div`
    flex:1;
    display:flex;
    align-items: center;
`;

export const RightComponentWrapper = styled.div`
    display:flex;
    align-items: center;
`;

export const Icon = styled.div`
    cursor: pointer;
`;

export const ButtonIcon = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-align: center;
    background-color: rgb(238, 238, 238);
    color: var(--secondary-color);
    font-size: 22px;
    width: 42px;
    height: 42px;
    outline: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    margin: 0px;
    text-decoration: none;
    flex: 0 0 auto;
    padding: 8px;
    border-radius: 50%;
    overflow: visible;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 8px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

export const SearchInputWrapper = styled.div`
    display:flex;
    width: 100%;
    aling-items:center;
    justify-content:space-between;
    gap:15px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    align-items: center;
    position: relative;
    border-radius: 10px;
    border: 1px solid rgb(238, 238, 238);
    transition: 0.3s;
    padding: 4px 8px;
    background-color: rgb(238, 238, 238) !important;

    input,
    input:focus,
    input:hover {
        flex: 1;
        font: inherit;
        letter-spacing: inherit;
        padding: 4px 0px 5px;
        border: 0px;
        box-sizing: content-box;
        background: none;
        height: 1.4375em;
        margin: 0px;
        display: block;
        min-width: 0px;
    }
`;

export const CustomDropdownMenu = styled(Dropdown.Menu)`
    &.show {
        max-height: 300px;
        overflow: auto;
    }

    .MuiBox-root {
        display: flex;
        align-items: center;
        min-width: 300px;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 20px;
        
        $:first-child {
            margin-bottom: 30px;
        }
    
        .label {
            font-size: 14px;
            margin-right: auto;
            word-break: break-all;
        }
    
        .left {
            float: left;
            margin-right: 20px;
            img {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                border: 1px solid #707271;
            }
        }

        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            overflow: hidden;
            padding-bottom: 10px;
            border-bottom: 1px solid #DAE3E2;
        }
    }
`;