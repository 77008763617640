import React, { Component, useEffect, useState } from "react";
import {
  Dropdown,
  Form,
  Spinner,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import IconButton from "../elements/buttons/IconButton";
import { logoutUser } from "lib/context/authActions";
import CustomToggleButtton from "../elements/buttons/CustomToggleButton";

import { useNavigate, useLocation } from "react-router-dom";
import IconLink from "../elements/buttons/IconLink";
import { connect, useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { useAuthDispatch } from "lib/context/authContext";
import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

import locciIcon from "../../assets/newsIcon.png";
import { getMyDefaultGroup, getMyGroups } from "../../api/groups";
import { applyFilterOnPosts } from "../../api/posts";
import filterIcon from "../../assets/menu.png";
import { notificationCount } from "../../api/common";
import { getMyProfile } from "../../api/user";
import { main_menu } from "../sidebar/menu";
import SidebarLink from "../elements/sidebar-link";
import { useAppDispatch, useAppSelector } from "lib/store";
import * as GlobalStore from 'lib/store/global';

function NavbarItems({ location, query, data, token, onPublicFeedToggle }) {

    const authDispatch = useAuthDispatch();
    const dispatch = useAppDispatch();
    const [isSearch, setIsSearch] = useState(false);
    const [loc, setLoc] = useState("");
    const [navQuery, setQuery] = useState("");
    const history = useNavigate();
    const [customClass, setCustomClass] = useState("");
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [myGroups, setMyGroups] = useState([]);

    const [defaultGrpId, setDefaultGrpId] = useState(null);
    const [count, setCount] = useState("+");
    const numbeOfNotifications = useAppSelector(GlobalStore.selectNumbeOfNotifications)
    const refreshNotification = useAppSelector(GlobalStore.selectRefreshNotification)
    const showToggleSearch = useAppSelector(GlobalStore.selectShowToggleSearch)
    const showRegionalData = useAppSelector(GlobalStore.selectShowRegionalData)
    const showPublicPostRight = useAppSelector(GlobalStore.selectShowPublicPostRight)
    const publicFeedIcon = useAppSelector(GlobalStore.selectPublicFeedIcon)
    const locations = useLocation();

    const { t, i18n } = useTranslation();
    const [showPostFilter, setShowPostFilter] = useState(false);

    const [selectAll, setSelectAll] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isPersonalized, toggleIsPersonalized] = useState(true);
    const [showRegionalFeed, setShowRegionalFedd] = useState(true) 

    useEffect(() => {
        getNotificationCount();
    }, [refreshNotification]);
    
    
    const getNotificationCount = async () => {
    try {
        let response = await notificationCount(token);
        const variable = parseInt(response?.data?.anz);
        dispatch(GlobalStore.updateNotificationCount(variable));
    } catch (error) {
        return error;
    }
    };

    return (
        <div className="navbar-actions">
            <ul>
                {main_menu?.map((me, i) => (
                <li key={i} className={locations?.pathname === me.path && "greenBackground"}>
                    <SidebarLink
                    key={i}
                    data={me}
                    outline={me?.outline && locations?.pathname !== me.path}
                    />
                    {i === 3 && numbeOfNotifications > 0 && (
                    <span className="notifictionCount">
                        {numbeOfNotifications > 99 ? "99+" : numbeOfNotifications}
                    </span>
                    )}
                </li>
                ))}
            </ul>
        </div>
    )
}

export default connect(mapStateToProps)(NavbarItems);