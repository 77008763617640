import React, { Component, useEffect, useState } from "react";
import {
  Dropdown,
  Form,
  Spinner,
} from "react-bootstrap";
import IconButton from "../elements/buttons/IconButton";
import CustomToggleButtton from "../elements/buttons/CustomToggleButton";

import { useNavigate, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { useAuthDispatch } from "lib/context/authContext";
import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import { getMyDefaultGroup, getMyGroups } from "../../api/groups";
import { applyFilterOnPosts } from "../../api/posts";
import { notificationCount } from "../../api/common";
import { useAppDispatch, useAppSelector } from "lib/store";
import * as GlobalStore from 'lib/store/global';

function NavbarActions({ location, query, data, token, onPublicFeedToggle }) {
  const authDispatch = useAuthDispatch();
  const dispatch = useAppDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const [loc, setLoc] = useState("");
  const [navQuery, setQuery] = useState("");
  const history = useNavigate();
  // const location = useLocation();
  const [customClass, setCustomClass] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  // const [myGroups, setMyGroups] = useState([]);
  // const [allGroups, setAllGroups] = useState([]);
  const [myGroups, setMyGroups] = useState([]);

  const [defaultGrpId, setDefaultGrpId] = useState(null);
  const [count, setCount] = useState("+");
  const numbeOfNotifications = useAppSelector(GlobalStore.selectNumbeOfNotifications)
  const refreshNotification = useAppSelector(GlobalStore.selectRefreshNotification)
  const showToggleSearch = useAppSelector(GlobalStore.selectShowToggleSearch)
  const showRegionalData = useAppSelector(GlobalStore.selectShowRegionalData)
  const showPublicPostRight = useAppSelector(GlobalStore.selectShowPublicPostRight)
  const publicFeedIcon = useAppSelector(GlobalStore.selectPublicFeedIcon)
  const locations = useLocation();

  const { t, i18n } = useTranslation();
  const [showPostFilter, setShowPostFilter] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isPersonalized, toggleIsPersonalized] = useState(true);
  const [showRegionalFeed, setShowRegionalFedd] = useState(true)   

  useEffect(() => {
    getMyDefaultGroupId(token);
  }, [token]);

  useEffect(() => {
    if (location != loc) {
      setIsSearch(false);
      setLoc(location);
    }
    if (query != navQuery) {
      setIsSearch(true);
      setQuery(query);
    }
  });

  useEffect(() => {
    addDynamicClass();
  }, [location]);

  useEffect(() => {
    if (!showRegionalData) { 
       fetchMyGroups();
    }
  }, [showRegionalData]);

  useEffect(() => {
    if (selectedGroups?.length > 0) setFilterOnPosts();
  }, [selectedGroups]);

  const getMyDefaultGroupId = () => {
    getMyDefaultGroup(token).then((res) => {
      if (res.data.OK) {
        const data = res.data.data;
        setDefaultGrpId(data);
      }
    });
  };

  const fetchMyGroups = () => {
    getMyGroups(token).then((res) => {
      if (res.data.OK) {
        let data = res.data.grp;
        setMyGroups([...data]);
        let show = res.data.show_regional === 'y';
        setShowRegionalFedd(show)
      }
    });
  };
   
  const allGroups = (showRegionalFeed)? [
    {
      name: t("regional_feed_title"),
      id: "public",
      userpic: publicFeedIcon,
      UCM_IsPersonalized: data?.LCU_IsPublicPersonalize,
    },
    ...myGroups, 
  ] : myGroups ;   

  const onSelectAllClick = (isSelectall) => {
    if (!isSelectall) {
      let selected = [];
      allGroups.forEach((me) => {
        selected.push({ id: me.id, status: "y" });
      });
      toggleIsPersonalized(true);

      setSelectedGroups([...selected]);
    } else {
      let selected = [];
      allGroups.forEach((me) => {
        selected.push({ id: me.id, status: "n" });
      });
      toggleIsPersonalized(false);
      setSelectedGroups([...selected]);
    }
  };

  const onToggleItem = (group) => {
    if (group.UCM_IsPersonalized === "y") {
      let groups = [];
      groups.push({
        id: group.id,
        status: "n",
      });
      setSelectedGroups([]);
      setSelectedGroups([...groups]);
    } else {
      let groups = [];

      groups.push({
        id: group.id,
        status: "y",
      });
      setSelectedGroups([...groups]);
      if (selectedGroups?.length === allGroups?.length) setSelectAll(true);
    }
  };

  const addDynamicClass = () => {
    if (location === "/") {
      setCustomClass("custom-mr");
    } else {
      let pathnames = ["/inbox", `/group/${location.split("/")[2]}`];
      let myClass = pathnames.includes(location) ? "custom-mr" : "";
      setCustomClass(myClass);
    }
  };


  const onToggleClick = () => {
    if (!showPostFilter){  
      fetchMyGroups();
    }
    setShowPostFilter(!showPostFilter);
    setLoader(false);
  };

  const setFilterOnPosts = async (status) => {  
    try {   
      let gids = [];

      selectedGroups.forEach((me) => { 
        if (me.id!==defaultGrpId) {
          gids.push(me.id);
        } 
      });   

      let params = {
        token: token,
        IsPersonalized:
          selectedGroups?.length === 1
            ? selectedGroups[0].status
            : isPersonalized
              ? "y"
              : "n",

        action: 66,
        GroupId:
          selectedGroups?.length === 1 ? selectedGroups[0].id : gids.join(","),
      };

      let response = await applyFilterOnPosts(params);

      if (response.data.OK) {
        fetchMyGroups();
        onPublicFeedToggle();
        dispatch(GlobalStore.togglePersonalizedAPI());
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    } 
  };

  return (
    <div className={"navbar-actions"}>
      {location === "/" && (
        <div className="filter-div">
          <Dropdown
            show={showPostFilter}
            onToggle={() => onToggleClick()}
            className="post-dd"
          >
            <Dropdown.Toggle id="dropdown-basic">
              <i className="material-icons white">filter_list</i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {loader && (
                <div className="filter-overlay">
                  <div className="loader">
                    <Spinner animation="border" role="status" color="#00395c">
                      <span className="sr-only">{t("loading")}...</span>
                    </Spinner>
                  </div>
                </div>
              )}
              <Box>
                <Typography component="div" className="lable" id="1234">
                  {t("deselect")}/{t("selectAll")}
                </Typography>
                <CustomToggleButtton
                  checked={allGroups.every(
                    (me) => me.UCM_IsPersonalized === "y"
                  )}
                  onChange={() => {
                    setLoader(true);
                    onSelectAllClick(
                      allGroups.every((me) => me.UCM_IsPersonalized === "y")
                    );
                  }}
                />
              </Box>

              {allGroups.length > 0 &&
                allGroups.map((group) => (
                  <Box key={group.id}>
                    <Typography component="div" className="left">
                      <img
                        src={
                          group.userpic ? group.userpic : "/images/profile.png"
                        }
                        alt="icon"
                      />
                    </Typography>
                    <Typography component="div" className="right">
                      <div className="lable">{group.name}</div>
                      {group.id === 0 && (
                        <Tooltip
                          title={t("feed_setting_public")}
                          placement="bottom-end"
                          arrow
                        >
                          <HelpOutlineOutlinedIcon />
                        </Tooltip>
                      )}

                      {group.id == defaultGrpId && (
                        <Tooltip
                          title={t("feed_setting_locci")}
                          placement="bottom-end"
                          arrow
                        >
                          <HelpOutlineOutlinedIcon />
                        </Tooltip>
                      )}
                      {console.log('group.UCM_IsPersonalized', group.UCM_IsPersonalized)}
                      <CustomToggleButtton
                        checked={
                          group.id === defaultGrpId 
                            ? true
                            : group.UCM_IsPersonalized === "y"
                              ? true
                              : false
                        }
                        onChange={() =>
                          group.id === defaultGrpId 
                            ? null
                            : onToggleItem(group)
                        }
                        className={group.id === defaultGrpId && "visiblehidden"}
                      />
                    </Typography>
                  </Box>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <div className="headerSearch">
        {isSearch && (
          <Form.Control
            type="text"
            autoFocus
            value={query}
            className="navbar-search-input open mr-15"
            placeholder={t("enter_search_text") + "..."}
            onChange={(e) => dispatch(GlobalStore.search(e.target.value))}
          />
        )}
        {locations?.pathname === "/notifications" ? (
          ""
        ) : !locations?.pathname?.includes("chat-info") ? (
          <IconButton
            icon={isSearch ? "close" : "search"}
            className="black mr-15"
            clear="true"
            handleClick={() => {
              setIsSearch(!isSearch);
              dispatch(GlobalStore.search(""));
            }}
          />
        ) : locations?.pathname?.includes("chat-info") && showToggleSearch ? (
          <IconButton
            icon={isSearch ? "close" : "search"}
            className="white mr-15"
            clear="true"
            handleClick={() => {
              setIsSearch(!isSearch);
              dispatch(GlobalStore.search(""));
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(NavbarActions);
