// import { useState } from "react";
import {
  Dropdown,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IconLink from "components/elements/buttons/IconLink";
import styled from "styled-components";
import { GroupSettingsData } from "lib/types/groupTypes";
import * as GlobalStore from "lib/store/global";
import { useAppSelector } from "lib/store";
import { useAuthState } from "lib/context";
import GlobalConfirmDialogue from "components/commons/GlobalConfirmDialogue";
import { useState } from "react";
// import { leaveGroup } from "api/groups";
import { useNavigate } from "react-router-dom";
import { leaveGroup } from "api/groups";

type ActionDropdownProps = {
    groupId: string | number;
    data?: GroupSettingsData;
    perms: any;
    isMember: boolean;
    onSettingsClick?: () => void;
};

const ActionDropdown = ({ groupId, perms, isMember, onSettingsClick }: ActionDropdownProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const defaultGrpId = useAppSelector(GlobalStore.selectMyDefaultGrpId);
    const [exitGroup, setExitGroup] = useState(false);
    
    return (
        <>
            <Dropdown className="ml-4">
                <Dropdown.Toggle id="dropdown-profile" as={ButtonIcon}>
                    <i className="material-icons">more_horiz</i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {perms.canInvite && (
                        <IconLink 
                            clear={true} 
                            link={"/group/" + groupId + "/invite"} 
                            text={t("group_portal_nav_invite")} 
                            icon={"person_add_alt"} 
                            className={"text"}
                        />
                    )}

                    {perms.canViewMembers && (
                        <IconLink 
                            clear={true} 
                            link={"/group/" + groupId + "/members"} 
                            text={t("group_portal_nav_members")} 
                            icon={"people_outline"} 
                            className={"text"} 
                        />
                    )}

                    {isMember && perms.canConfirmInvite && (
                        <IconLink 
                            clear={true} 
                            link={"/group/" + groupId + "/requests"} 
                            text={t("group_portal_nav_req")} 
                            icon={"error_outline"} 
                            className={"text"} 
                        />
                    )}

                    {isMember && userDetails.token && (
                        <IconLink 
                            clear={true} 
                            link={"/group-settings/" + groupId} 
                            text={t("manage_notifications")} 
                            icon={"notifications"} 
                            className={"text"} 
                        />
                    )}

                    {perms.isAdmin && 
                        <IconLink 
                            clear={true} 
                            handleClick={onSettingsClick} 
                            text={t("edit")} 
                            icon={"edit"} 
                            className={"text"}  
                        />
                    }
                    
                    {isMember && defaultGrpId != groupId && (
                        <IconLink 
                            clear={true} 
                            text={t("group_portal_leave_group")} 
                            icon={"exit_to_app"} 
                            className={"text"} 
                            disabled={perms.isSuperAdmin}
                            handleClick={() => setExitGroup(true)}
                        />
                    )}
                </Dropdown.Menu>
            </Dropdown>

            <GlobalConfirmDialogue
                show={exitGroup}
                title={"group_portal_leave_modal_leave_button_text"}
                content={"group_portal_leave_group_text"}
                name={""}
                closeModal={() =>
                    setExitGroup(false)
                }
                onSubmit={() => {
                    console.log(userDetails.token);
                    leaveGroup(userDetails.token, groupId); 
                    setExitGroup(false);
                    navigate("/groups");
                }}
            />
        </>
    );
};

const ButtonIcon = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-align: center;
    background-color: rgb(238, 238, 238);
    color: var(--secondary-color);
    font-size: 22px;
    width: 32px;
    height: 32px;
    outline: 0px;
    border: initial;
    border-width: 0px;
    margin: 0px;
    text-decoration: none;
    padding: 8px;
    border-radius: 50%;
    overflow: visible;
    // transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export default ActionDropdown;