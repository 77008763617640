import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { revertAll } from "../ui";

type GlobalState = {
  query: string;
  location: string;
  postFilter: any[];
  myDefaultGrpId: string | null;
  myGroups: any[];
  shouldCallPersonlizedAPI: boolean;
  numbeOfNotifications: number | null;
  refreshNotification: number;
  forwardMessage: any | null;
  draftMessage: any | null;
  showToggleSearch: boolean;
  showAddPost: boolean;
  showRegionalData: boolean;
  showPublicPostRight: boolean;
  isPublicPersonalize: boolean;
  publicFeedIcon: string | null;
};

const initialState: GlobalState = {
  query: "",
  location: "/",
  postFilter: [],
  myDefaultGrpId: null,
  myGroups: [],
  shouldCallPersonlizedAPI: false,
  numbeOfNotifications: null,
  refreshNotification: 1,
  forwardMessage: null,
  draftMessage: null,
  showToggleSearch: false,
  showAddPost: true,
  showPublicPostRight: true,
  showRegionalData: true,
  isPublicPersonalize: true,
  publicFeedIcon: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleGlobalSet: (state, action: PayloadAction<boolean>) => {
      state.showToggleSearch = action.payload;
    },
    search: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    routeChanged: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
      state.query = "";
    },
    showHideAddPost: (state, action: PayloadAction<boolean>) => {
      state.showAddPost = action.payload;
    },
    filterPosts: (state, action: PayloadAction<any>) => {
      state.postFilter = action.payload;
      state.query = "";
    },
    setForwardMessage: (state, action: PayloadAction<any>) => {
      state.forwardMessage = action.payload;
    },
    setMyDraftMessage: (state, action: PayloadAction<any>) => {
      state.draftMessage = action.payload;
    },
    updateNotificationCount: (state, action: PayloadAction<number | string>) => {
      if (action.payload === "") {
        state.refreshNotification = state.refreshNotification + 1;
      } else {
        state.numbeOfNotifications = Number(action.payload);
      }
    },
    getMyDefaultGroupId: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-console
      // console.log("getMyDefaultGroupId", action.payload);
      state.myDefaultGrpId = action.payload;
      // state.myDefaultGrpId = null;
    },
    getShowRegionals: (state, action: PayloadAction<boolean>) => {
      state.showRegionalData = action.payload;
    },
    setIsPublicPersonalize: (state, action: PayloadAction<boolean>) => {
      state.isPublicPersonalize = action.payload;
    },
    getShowPublicPostRight: (state, action: PayloadAction<boolean>) => {
      state.showPublicPostRight = action.payload;
    },
    getMyGroups: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-console
      console.log("getMyGroups", action.payload);
      // state.myGroups = action.payload;
      state.myGroups = [];
    },
    setPublicFeedIcon: (state, action: PayloadAction<any>) => {
      state.publicFeedIcon = action.payload;
    },
    togglePersonalizedAPI: (state) => {
      state.shouldCallPersonlizedAPI = !state.shouldCallPersonlizedAPI;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  toggleGlobalSet,
  search,
  routeChanged,
  showHideAddPost,
  filterPosts,
  setForwardMessage,
  setMyDraftMessage,
  updateNotificationCount,
  getMyDefaultGroupId,
  getShowPublicPostRight,
  getShowRegionals,
  getMyGroups,
  togglePersonalizedAPI,
  setIsPublicPersonalize,
  setPublicFeedIcon,
} = globalSlice.actions;

export const selectQuery = (state: RootState) => state.global.query;
export const selectLocation = (state: RootState) => state.global.location;
export const selectPostFilter = (state: RootState) => state.global.postFilter;
export const selectMyDefaultGrpId = (state: RootState) => state.global.myDefaultGrpId;
export const selectMyGroups = (state: RootState) => state.global.myGroups;
export const selectShouldCallPersonlizedAPI = (state: RootState) =>
  state.global.shouldCallPersonlizedAPI;
export const selectNumbeOfNotifications = (state: RootState) => state.global.numbeOfNotifications;
export const selectRefreshNotification = (state: RootState) => state.global.refreshNotification;
export const selectForwardMessage = (state: RootState) => state.global.forwardMessage;
export const selectDraftMessage = (state: RootState) => state.global.draftMessage;
export const selectShowToggleSearch = (state: RootState) => state.global.showToggleSearch;
export const selectShowAddPost = (state: RootState) => state.global.showAddPost;
export const selectShowRegionalData = (state: RootState) => state.global.showRegionalData;
export const selectShowPublicPostRight = (state:RootState) => state.global.showPublicPostRight;
export const selectIsPublicPersonalize = (state:RootState) => state.global.isPublicPersonalize;
export const selectPublicFeedIcon = (state: RootState) => state.global.publicFeedIcon;

export default globalSlice.reducer;
