import React, { useEffect, useState } from "react";
import { side_menu } from "./menu";
import "./Sidebar.scss";
import SidebarLink from "../elements/sidebar-link";
import { logoutUser } from "lib/context/authActions";
import { useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "lib/context/authContext";
import { useAppSelector } from "lib/store";
import * as GlobalStore from 'lib/store/global';
import { LocciAvatar } from "components/commons/LocciAvatar";
import { useTranslation } from "react-i18next";
import { Badge } from "react-bootstrap";

function Sidebar(props) {
  const { t } = useTranslation();
  const authDispatch = useAuthDispatch()
  const userDetails = useAuthState();
  const history = useNavigate();
  const location = useAppSelector(GlobalStore.selectLocation);
  const [userProfile, setUserProfile] = useState(null);
  
  
  useEffect(()=>{
    setUserProfile(userDetails?.profile);
  },[userDetails])
  
  function handleClick(item) {
    // props.setMenu(false);
    if(item.name=='logout')
    {
      logout()
    }
  }

  const logout = async (e) => {
    try {
      await logoutUser(authDispatch)
        .then((res) => {
          history("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {props.isMenu && (
        <div
          className="sidebar-black-overlay"
          onClick={() => props.setMenu(false)}
        ></div>
      )}
      <div className={"sidebar-menu " + (props.isMenu ? "menu-opened" : "")}>
        <div className="menu-holder">
        {userProfile && (
          <>
            <div className="d-flex mb-2" style={{gap:"15px"}}>
              <LocciAvatar
                title={`${userProfile.USE_firstname} ${userProfile.USE_surename}`}
                picture={userProfile.USE_pic}
              ></LocciAvatar>
              <div className="d-flex flex-column">
                <div className="author-name">{`${userProfile.USE_firstname} ${userProfile.USE_surename}`}</div>
                <div className="small">{`${userProfile.CIT_plz}, ${userProfile.CIT_name}`}</div>
              </div>
            </div>

            <div className="d-flex mb-4 user-stats" style={{gap:"20px"}}>
              <div>
                {t('followers')} <Badge>{userProfile.follewers}</Badge>
              </div>
              <div>
                {t('i_follow')} <Badge>{userProfile.following}</Badge>
              </div>
            </div>
          </>
        )}

          {side_menu.map((item, i) => (
            <>
              <SidebarLink
                key={i}
                data={item}
                active={location === item.path}
                handleClick={() => handleClick(item)}
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
